import React from "react"
import { Breadcrumb } from "antd"
import { Content } from "antd/lib/layout/layout"
import { Link } from "gatsby"

import MenuButton from "../../components/fundamentals/button/menu-button"
import NotifyTop from "../../components/news-list"
export default function HomeContent() {
  return (
    <div>
      <Content>
        <div
          style={{
            padding: 0,
            minHeight: 360,
            backgroundColor: "#FAFAFA",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          {/* <ChartIndex></ChartIndex> */}
          <div className="flex min-w-full min-h-[230px] justify-center items-end mt-[20px]">
            <MenuButton />
          </div>
          <div className="flex min-w-full min-h-[230px] justify-center items-end mt-[116px]">
            <div className=" bg-[#FFFFFF] rounded-[8px] p">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="redHeadText pt-4">新着ニュース</span>
              </div>
              <hr
                style={{
                  width: "1120px",
                  margin: "20px auto 0px auto",
                  border: "0.1px solid rgba(0, 0, 0, 0.06)",
                }}
              ></hr>
              <NotifyTop />
            </div>
          </div>
        </div>
      </Content>
    </div>
  )
}
